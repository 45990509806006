<!--
 * @Author: 月魂
 * @Date: 2021-11-16 13:23:03
 * @LastEditTime: 2022-11-14 15:57:33
 * @LastEditors: 月魂
 * @Description: 艺术节介绍
 * @FilePath: \food-show\src\views\Intro.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onLeftClick"
    />
    <!-- <div class="header">
      <img src="../assets/poster-pc.jpg" alt="" class="header-pic mobile">
      <img src="../assets/home.jpg" alt="" class="header-pic pc" width="100%">
    </div> -->
    <!-- <video controls class="video" poster="http://image.wearetogether.com.cn/seven-silk-intro.png">
      <source src="http://image.wearetogether.com.cn/seven-silk-intro.mp4" >
    </video> -->
    <div class="cont">
      <div class="about">
        <div class="title">
          {{ this.$t('intro.title') }}
        </div>
        <div class="intro">
          <div class="intro-cont" v-for="(text, index) in this.$t('intro.des')" :key="index">{{ text }}</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar } from 'vant';
import { handleBack } from '../utils'

Vue.use(NavBar);

export default {
  name: 'Intro',
  data() {
    return {
      title: '',
    }
  },
  mounted() {
    this.title = this.$t('title')
    this.wxShare(this.$t('title'))
  },
  methods: {
    onLeftClick() {
      handleBack()
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  max-width: 1200PX;
  margin: auto;
  .header {
    position: relative;
    .header-pic {
      display: block;
      width: 100%;
    }
    .pc {
      display: none;
    }
    @media (min-width: 1200PX) {
      .mobile {
        display: none;
      }
      .pc {
        display: block;
      }
    }
    .back {
      position: absolute;
      top: 16px;
      left: 10px;
      width: 24PX;
    }
  }
  .video {
    display: block;
    width: 100%;
    video {
      object-fit: fill;
    }
  }
  .cont {
    // background-image: linear-gradient(to right, #ec6e5b, #b27c9e);
    .about {
      max-width: 1200PX;
      margin: auto;
      padding-bottom: 30px;
      // color: #fff;
      .title {
        text-align: center;
        padding-top: 10px;
        font-size: 20px;
      }
      .intro {
        .intro-cont {
          padding: 0 20px;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 1.5;
          margin-top: 5px;
          color: #9a9a9a;
          @media (min-width: 1200PX) {
            & {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
</style>
